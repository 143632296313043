import get from 'lodash/get'

import isTypeTransport from 'app/Apps/OrderManagement/Orders/utils/isTypeTransport'

import mapOrderSteps from './mapOrderSteps'
import {
    StepData,
} from '../../createManuallyForm.types'
import stepsRules from './stepsRules'

const DELIVERY_LOCATION = 'deliveryLocation'
const LOCATION = 'location'

export const getListSteps = (orderSteps): StepData[] => {
    return mapOrderSteps.filter((step: StepData) => {
        const selectedSteps = orderSteps?.map(({
            stepTypeDetailed,
        }) => { return stepTypeDetailed })

        const path = [
            ...selectedSteps,
            step.stepTypeDetailed,
        ].join('.')

        return get(stepsRules, path)
    })
}

export const getLocation = ({
    isFirstStep,
    orderSteps,
    location,
}) => {
    if (isFirstStep) {
        return location
    }

    const delivery = isTypeTransport(orderSteps?.[orderSteps.length - 1]?.stepType)
        ? DELIVERY_LOCATION
        : LOCATION

    return orderSteps?.[orderSteps.length - 1][delivery]
}
