import {
    LeaseType,
    OrderStatuses,
    OrderTemperatureRange,
} from 'app/types/enums'

import {
    CancellationReasons,
    DangerousGoods,
    DeliveryServiceType,
    Order,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import {
    DTOCardFormField, FieldInCard,
} from 'app/types/form.types'
import {
    NestedKeyOf,
} from 'app/types/utils.types'
import {
    IncoTerm,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'

export type CustomerTransportOverviewFields = NestedKeyOf<Order>

const getFields = ({
    orderStatus,
    isCancelled,
    leaseType,
    laneId,
    getAllowedValues,
}: {
    orderStatus: OrderStatuses,
    isCancelled: boolean,
    leaseType: LeaseType,
    laneId: number,
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>
}): DTOCardFormField<CustomerTransportOverviewFields>[] => {
    const freightForwarderField: FieldInCard<CustomerTransportOverviewFields>[] = [{
        name: 'freightForwarder',
        componentName: 'CompanyObjectSelector',
        labelKey: 'OrderByNumberView.freightForwarder',
    }]
    const orderInfoCard: DTOCardFormField<CustomerTransportOverviewFields> = {
        id: 'orderInfo',
        className: 'orderInfo',
        title: 'Order Info',
        fields: [
            {
                name: 'orderNumber',
                componentName: 'InputSingleline',
                labelKey: 'OrderByNumberView.orderNumber',
            },
            {
                name: 'temperatureRange',
                componentName: 'EnumSelector',
                allowedValues: getAllowedValues(OrderTemperatureRange),
                labelKey: 'OrderByNumberView.temperatureRange',
            },
            {
                name: 'createdBy',
                componentName: 'InputSingleline',
                labelKey: 'OrderByNumberView.createdBy',
            },
        ],
    }
    const summaryCard: DTOCardFormField<CustomerTransportOverviewFields> = {
        id: 'summary',
        className: 'summary',
        title: 'Summary',
        fields: [
            {
                id: 'column1',
                fields: [
                    {
                        name: 'lane.laneNumber',
                        componentName: 'LinkTo',
                        link: `/apps/ordermanagement/lanes/all/edit/${laneId}/laneimplementation`,
                        labelKey: 'OrderLaneView.laneNumber',
                    },
                    {
                        name: 'requestedHandover',
                        componentName: 'InputSingleline',
                        labelKey: 'OrderByNumberView.requestedHandover',
                    },
                    {
                        name: 'billing.leaseStartTimestamp',
                        componentName: 'DateTimeZonedSelect',
                        labelKey: 'CustomerTransportCreateDto.leaseStartTimestamp',
                    },
                    {
                        name: 'billing.baseLeaseDays',
                        componentName: 'InputSingleline',
                        labelKey: 'OrderBillingView.baseLeaseDays',
                    },
                    {
                        name: 'billing.baseLeaseUntilTimestamp',
                        componentName: 'InputSingleline',
                        labelKey: 'OrderBillingView.baseLeaseUntilTimestamp',
                    },
                    {
                        name: 'deliveryServiceType',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(DeliveryServiceType),
                        labelKey: 'OrderByNumberView.deliveryServiceType',
                    },
                    {
                        name: 'orderReceived',
                        componentName: 'DateTimeZonedSelect',
                        labelKey: 'OrderByNumberView.orderReceived',
                    },
                    {
                        name: 'orderEntered',
                        componentName: 'InputSingleline',
                        labelKey: 'OrderByNumberView.orderEntered',
                    },
                    {
                        name: 'airline.companyName',
                        componentName: 'InputSingleline',
                        labelKey: 'OrderStep.airline',
                    },
                    {
                        name: 'dangerousGoodsClass',
                        componentName: 'EnumSelector',
                        allowedValues: DangerousGoods,
                        labelKey: 'Order.dangerousGoodsClass',
                    },
                    {
                        name: 'customerComment',
                        componentName: 'InputSingleline',
                        labelKey: 'Order.customerComment',
                    },
                ],
            },
            {
                id: 'column2',
                fields: [
                    {
                        name: 'customerReference',
                        componentName: 'InputSingleline',
                        labelKey: 'OrderByNumberView.customerReference',
                    },
                    {
                        name: 'leaseType',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(LeaseType),
                        labelKey: 'OrderByNumberView.leaseType',
                    },
                    {
                        name: 'personInCharge',
                        componentName: 'InputSingleline',
                        labelKey: 'OrderByNumberView.personInCharge',
                    },
                    {
                        name: 'allowedProductTypes',
                        componentName: 'AllowedContainerTypes',
                        noLabel: true,
                    },
                    {
                        name: 'transportModeRoad',
                        componentName: 'InputSingleline',
                        labelKey: 'OrderByNumberView.transportModeRoad',
                    },
                    {
                        name: 'transportModeForwarder',
                        componentName: 'InputSingleline',
                        labelKey: 'Order.transportModeForwarder',
                    },
                    {
                        name: 'pricingAccount.companyName',
                        componentName: 'InputSingleline',
                        labelKey: 'Prebooking.pricingAccount',
                    },
                    {
                        name: 'commentForBillingOps',
                        componentName: 'LongText',
                        labelKey: 'OrderByNumberView.commentForBillingOps',
                    },
                    ...leaseType === LeaseType.AIRLINE_LEASE ? freightForwarderField as [] : [],
                    {
                        name: 'incoTerm',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(IncoTerm),
                        labelKey: 'OrderByNumberView.incoTerm',
                    },
                ],
            },
        ],
    }
    const orderStatusCard: DTOCardFormField<CustomerTransportOverviewFields> = {
        id: 'orderStatus',
        className: 'orderStatus',
        title: 'Order Status',
        fields: [
            {
                name: 'orderStatus',
                componentName: 'EnumSelector',
                allowedValues: getAllowedValues(OrderStatuses),
                labelKey: 'OrderByNumberView.orderStatus',
            },
            {
                name: 'orderSteps',
                componentName: 'OrderStatus',
                labelKey: 'OrderByNumberView.orderSteps',
            },
        ],
    }
    const cancellationCard: DTOCardFormField<CustomerTransportOverviewFields> = {
        id: 'Cancellation',
        className: 'cancellation',
        title: 'Cancellation Info',
        fields: [
            {
                name: 'cancellationDate',
                componentName: 'DateTimeZonedSelect',
                labelKey: 'OrderByNumberView.cancellationDate',
            },
            {
                name: 'cancellationReason',
                componentName: 'EnumSelector',
                allowedValues: getAllowedValues(CancellationReasons),
                labelKey: 'OrderByNumberView.cancellationReason',
            },
            {
                name: 'cancellationComment',
                componentName: 'InputSingleline',
                labelKey: 'OrderByNumberView.cancellationComment',
            },
        ],
    }
    const consigneeCard: DTOCardFormField<CustomerTransportOverviewFields> = {
        id: 'consignee',
        className: 'consignee',
        title: 'Consignee',
        fields: [
            {
                name: 'consignee',
                componentName: 'LocationObjectSelector',
                labelKey: 'OrderByNumberView.consignee',
            },
            {
                name: 'consigneeContacts',
                componentName: 'MultipleContactObjectSelector',
                labelKey: 'OrderByNumberView.consigneeContacts',
            },
        ],
    }

    const customerCard: DTOCardFormField<CustomerTransportOverviewFields> = {
        id: 'customer',
        className: 'customer',
        title: 'Customer',
        fields: [
            {
                name: 'customer',
                componentName: 'LocationObjectSelector',
                labelKey: 'OrderByNumberView.customer',
            },
            {
                name: 'customerContacts',
                componentName: 'MultipleContactObjectSelector',
                labelKey: 'OrderByNumberView.customerContacts',
            },
        ],
    }
    const orderStatusHistoryCard: DTOCardFormField<CustomerTransportOverviewFields> = {
        id: 'orderStatusHistory',
        className: 'orderStatusHistory',
        title: 'Order Status History',
        fields: [{
            name: 'orderStatusTransitions',
            componentName: 'OrderStatusHistory',
            orderStatus,
            noLabel: true,
        }],
    }
    const selectedAssetCard: DTOCardFormField<CustomerTransportOverviewFields> = {
        id: 'selectedAsset',
        className: 'selectedAsset',
        title: 'Booked Containers',
        classNames: (classes) => {
            return {
                wrapper: classes.wrapper,
                title: classes.title,
            }
        },
        fields: [{
            name: 'selectedContainers',
            componentName: 'BookedContainers',
            labelKey: 'OrderByNumberView.selectedContainers',
        }],
    }
    const originCard: DTOCardFormField<CustomerTransportOverviewFields> = {
        id: 'origin',
        title: 'Origin',
        className: 'origin',
        fields: [
            {
                name: 'originLocation',
                componentName: 'LocationObjectSelector',
                labelKey: 'OrderByNumberView.originLocation',
            },
            {
                name: 'originAirport',
                componentName: 'LocationObjectSelector',
                labelKey: 'OrderByNumberView.originAirport',
            },
            {
                name: 'originLocationContacts',
                componentName: 'MultipleContactObjectSelector',
                labelKey: 'OrderByNumberView.originLocationContacts',
            },
        ],
    }
    const handoverPointCard: DTOCardFormField<CustomerTransportOverviewFields> = {
        id: 'handoverPoint',
        title: 'Handover Point',
        className: 'handoverPoint',
        fields: [
            {
                name: 'handoverPoint',
                componentName: 'LocationExpandedViewObjectSelector',
                labelKey: 'OrderByNumberView.handoverPoint',
            },
            {
                name: 'handoverPointContacts',
                componentName: 'MultipleContactObjectSelector',
                labelKey: 'OrderByNumberView.handoverPointContacts',
            },
        ],
    }
    const dropOffPointCard: DTOCardFormField<CustomerTransportOverviewFields> = {
        id: 'dropOffPoint',
        title: 'Collection / Drop-off Point',
        className: 'dropOffPoint',
        fields: [
            {
                name: 'collectionDropoffPoint',
                componentName: 'LocationExpandedViewObjectSelector',
                labelKey: 'OrderByNumberView.collectionDropoffPoint',
            },
            {
                name: 'collectionDropoffPointContacts',
                componentName: 'MultipleContactObjectSelector',
                labelKey: 'OrderByNumberView.collectionDropoffPointContacts',
            },
        ],
    }
    const destinationCard: DTOCardFormField<CustomerTransportOverviewFields> = {
        id: 'destination',
        title: 'Destination',
        className: 'destination',
        fields: [
            {
                name: 'destinationLocation',
                componentName: 'LocationObjectSelector',
                labelKey: 'OrderByNumberView.destinationLocation',
            },
            {
                name: 'destinationAirport',
                componentName: 'LocationObjectSelector',
                labelKey: 'OrderByNumberView.destinationAirport',
            },
            {
                name: 'destinationLocationContacts',
                componentName: 'MultipleContactObjectSelector',
                labelKey: 'OrderByNumberView.destinationLocationContacts',
            },
        ],
    }

    return isCancelled ? [
        orderInfoCard,
        summaryCard,
        orderStatusCard,
        cancellationCard,
        consigneeCard,
        customerCard,
        orderStatusHistoryCard,
        selectedAssetCard,
        originCard,
        handoverPointCard,
        dropOffPointCard,
        destinationCard,
    ] : [
        orderInfoCard,
        summaryCard,
        orderStatusCard,
        consigneeCard,
        customerCard,
        orderStatusHistoryCard,
        selectedAssetCard,
        originCard,
        handoverPointCard,
        dropOffPointCard,
        destinationCard,
    ]
}

export default getFields
