import {
    useCallback,
} from 'react'

import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from '@skycell-ag/auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'
import {
    AsyncMutationOptions,
} from 'app/types/request.types'

import {
    OpportunityType,
} from '../../opportunities.types'

import requests from '../../opportunities.request'

export default () => {
    const token = useJWTToken()

    const {
        onSuccess,
        onError,
    } = useHandleStatusRequest({
        title: 'New opportunities were created',
        id: 'OpportunitiesBulkCreate',
    })

    const {
        mutateAsync: createOpportunities,
    } = useMutation({
        mutationFn: (opportunities: OpportunityType[]) => {
            return simpleApi({
                ...requests.bulkCreate({
                    data: opportunities,
                }),
                token,
            })
        },
        onError,
        onSuccess,
    })

    return useCallback(async (opportunities: OpportunityType[], options?: AsyncMutationOptions) => {
        return createOpportunities(opportunities, options)
    }, [createOpportunities])
}
