import React, {
    useCallback,
} from 'react'
import {
    Redirect,
    Route,
    Switch,
    useRouteMatch,
} from 'react-router-dom'

import useHasPermission from 'app/hooks/useHasPermission'
import PreBookings from 'app/Apps/PreBooking'
import KamDashboard from './KamDashboard'
import Accounts from './Accounts'
import RegionalGroups from './RegionalGroups'
import Opportunities, {
    OpportunitiesBulkCreate, OpportunitiesBulkEdit,
} from './Opportunities'
import AccountLanes, {
    AccountLaneBulkCreate,
} from './AccountLanes'
import AccountQuotes from './AccountQuotes'

const AccountManagement = () => {
    const {
        path,
    } = useRouteMatch()
    const createPermission = useHasPermission([
        'account_create_all',
        'account_create_restricted',
    ])
    const updatePermission = useHasPermission([
        'account_update_all',
        'account_update_restricted',
    ])

    const renderOpportunitiesBulkCreate = useCallback(() => {
        if (createPermission) {
            return <OpportunitiesBulkCreate backUrl={`${path}/opportunities`} />
        }
        return <Redirect to={path} />
    }, [
        createPermission,
        path,
    ])

    const renderOpportunitiesBulkEdit = useCallback(() => {
        if (updatePermission) {
            return <OpportunitiesBulkEdit backUrl={`${path}/opportunities`} />
        }
        return <Redirect to={path} />
    }, [
        updatePermission,
        path,
    ])

    const renderAccountLaneBulkCreate = useCallback(() => {
        if (createPermission) {
            return <AccountLaneBulkCreate backUrl={`${path}/lanes`} />
        }
        return <Redirect to={path} />
    }, [
        createPermission,
        path,
    ])

    return (
        <Switch>
            <Route path={`${path}/kam-dashboard`}>
                <KamDashboard />
            </Route>
            <Route path={`${path}/accounts`}>
                <Accounts />
            </Route>
            <Route path={`${path}/regional-groups`}>
                <RegionalGroups />
            </Route>
            <Route
                path={`${path}/opportunities/bulk-create`}
                render={renderOpportunitiesBulkCreate}
                exact
            />
            <Route
                path={`${path}/opportunities/bulk-edit`}
                exact
                render={renderOpportunitiesBulkEdit}
            />
            <Route path={`${path}/opportunities`}>
                <Opportunities />
            </Route>
            <Route
                path={`${path}/lanes/bulk-create`}
                render={renderAccountLaneBulkCreate}
                exact
            />
            <Route path={`${path}/lanes`}>
                <AccountLanes />
            </Route>
            <Route path={`${path}/quotes/`}>
                <AccountQuotes />
            </Route>
            <Route path={`${path}/pre-bookings/`}>
                <PreBookings />
            </Route>
            <Redirect
                exact
                path={path}
                to={`${path}/accounts`}
            />
            <Route path="/">
                <div>
                    Not found
                </div>
            </Route>
        </Switch>
    )
}

export default AccountManagement
