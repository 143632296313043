import {
    useCallback,
} from 'react'

import {
    useMutation,
} from '@tanstack/react-query'

import simpleApi from 'app/services/simpleApi'
import {
    useJWTToken,
} from '@skycell-ag/auth'
import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import requests from '../Companies.request'

type CompanyParams = { companyName: string, website: string, awbPrefix: number }

export default (onSuccessAction) => {
    const token = useJWTToken()

    const {
        onStartSaving,
        onError: onFailedRequest,
        removePendingStatus,
    } = useHandleStatusRequest({
        id: 'CompanyDuplicationCheck',
    })

    const onSuccess = useCallback((data) => {
        removePendingStatus()
        onSuccessAction(data)
    }, [
        removePendingStatus,
        onSuccessAction,
    ])

    const onError = useCallback((err) => {
        onFailedRequest(err)
    }, [onFailedRequest])

    const {
        mutate: checkDuplication,
    } = useMutation({
        mutationFn: (data: CompanyParams) => {
            return simpleApi({
                ...requests.duplication({
                    data,
                }),
                token,
            })
        },
        onSuccess,
        onError,
    })

    return useCallback((companyParams: CompanyParams) => {
        onStartSaving('Checking Database for similar companies')
        checkDuplication(companyParams)
    }, [
        checkDuplication,
        onStartSaving,
    ])
}
