import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'

import {
    AttitudeToSkyCell, EngagementTableType,
} from './engagement.types'

const config: SkyNetTableConfig<keyof EngagementTableType> = {
    name: 'EngagementTable',
    tabs: [{
        name: 'All',
        url: 'all',
    }],
    fields: [
        {
            name: 'contactName',
            reduced: true,
            labelKey: 'Engagement.contactName',
        },
        {
            name: 'contactCompanyName',
            filterField: 'contactCompanyName',
            filterType: FilterType.Array,
            reduced: true,
            labelKey: 'Engagement.contactCompanyName',
        },
        {
            name: 'contactCountryName',
            filterField: 'contactCountryName',
            filterType: FilterType.Array,
            reduced: true,
            labelKey: 'Engagement.contactCountryName',
        },
        {
            name: 'attitudeToSkyCell',
            filterField: 'attitudeToSkyCell',
            filterType: FilterType.Array,
            componentName: 'AttitudeToSkyCellTable',
            allowedValuesEnum: AttitudeToSkyCell,
            labelKey: 'Engagement.attitudeToSkyCell',
        },
    ],
}

export default config
