import {
    Currency, LaneFeesStatus, LeaseType, OpportunityPalletType, ProductType, RangeType,
} from 'app/types/enums'
import {
    Location,
} from 'app/shared-components/LocationSelector'
import {
    Contact,
} from 'app/types/common.types'
import {
    Account,
} from '../AccountManagement.types'
import {
    OpportunityType,
} from '../Opportunities/opportunities.types'
import {
    AccountLane, AccountLanesTable, LaneStatus,
} from '../AccountLanes/AccountLanes.types'
import {
    CollectionServiceType,
    DeliveryServiceType,
} from '../../OrderManagement/Orders/orders.types'
import {
    LaneGroup, TruckingCapacity,
} from '../../Pricing/Pricing.types'

export enum QuoteStatus {
    CREATED = 'CREATED',
    IN_PROGRESS = 'IN_PROGRESS',
    CLOSED = 'CLOSED',
    CANCELLED = 'CANCELLED',
}

export type AccountQuoteTable = {
    accountName: string,
    changedOn: string,
    currency: Currency,
    id: number,
    quoteNumber: string,
    quotedForCompanyLocationName: string,
    requestedBy: string,
    createdBy: string,
    quoteStatus: QuoteStatus,
}

export type RequestedOpportunity = {
    id: number,
    opportunity: OpportunityType,
    productTypes: ProductType[],
    palletType: OpportunityPalletType
}

export type RequestedLane = {
    id: number,
    accountLane: AccountLane,
    baseLeaseDays?: number[],
    otherComments: string
}

export type AccountQuote = {
    id: number,
    createdOn: string,
    changedOn: string,
    createdBy: string,
    changedBy:string,
    quoteNumber: string,
    quoteStatus: QuoteStatus,
    account: Account,
    currency: Currency,
    leaseType: LeaseType,
    quotedForCompany: Location,
    quotedFor: string,
    requestedBy: {
        id: number
    },
    isPerformanceQualification: boolean,
    requestedOpportunities: RequestedOpportunity[],
    requestedLanes: RequestedLane[],
    supportingDocuments?: {id: number}[],
    pricingResponsible: Contact,
    comment: string,
    customerQuotation: Location,
    laneStatus: LaneStatus,
    plannedContractBasis: {
        id: number,
    },
    linkedContractBasis: {
        id: number,
    },
    contractBasisNumber: string,
}

export type LaneToBeGrouped = Pick<AccountLanesTable,
    'id' | 'changedOn' | 'laneNumber' | 'originAirport' | 'destinationAirport' | 'deliveryServiceType' |
    'collectionServiceType' | 'changedBy'> & {
    laneStatus: LaneStatus,
    laneGroupNumber: string,
}

export type AssignPricingLaneGroupsTable = {
    deliveryServiceType: DeliveryServiceType,
    handoverPointLocationName: string,
    collectionServiceType: CollectionServiceType,
    collectionDropoffPointLocationName: string,
    leaseType: LeaseType,
    originAirportsIataCode: string[],
    destinationAirportsIataCode: string[],
    tempRange: string,
    laneGroupNumber: string,
    laneFeesValidTo?: string,
    additionalFeesValidTo?: string,
}

export type AwardRejectLaneFeesTable = {
    id: number,
    changedOn: string,
    changedBy: string,
    laneFeesNumber: string,
    laneGroupNumber: string,
    laneFeesStatus: LaneFeesStatus,
    validFrom: string,
    validTo: string,
    truckingCapacity: TruckingCapacity[],
    rentalDays: number,
    logisticsDays: number,
    expressOrderTimeline: number,
    expressDaysType: RangeType,
    postponementFeeTimeline: number,
    postponementDaysType: RangeType,
    isPerformanceQualification: boolean
}

export type AssignPricingLaneGroupResponse = {
    laneGroup: LaneGroup,
    quote: {
        id: 0,
        changedOn: string,
        baseLeaseDays: number[]
    },
    lanes: AccountLane[],
    requestedOpportunity: RequestedOpportunity
}

export type RequestDataUpdate = {
    requestedOpportunities: {
        opportunity: { id: number },
        productTypes: ProductType[],
        palletType: OpportunityPalletType
    }[],
    requestedLanes: {
        accountLane: { id: number },
        baseLeaseDays?: number[],
        otherComments: string
    }[]
}
