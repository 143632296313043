import {
    makeStyles,
} from 'app/tss'

const lane = 'lane'
const prebooking = 'prebooking'
const status = 'status'
const ops = 'ops'
const kam = 'kam'
const general = 'general'

const useStyles = makeStyles()((theme) => {
    return {
        cardContent: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(1.5),
            gridTemplateAreas: `
                "${lane} ${prebooking}"
                "${status} ${prebooking}"
                "${ops} ${kam}"
                `,
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        gridWrapperWithGeneral: {
            display: 'grid',
            gridGap: theme.spacing(1.5),
            gridTemplateAreas: `
                "${general} ${general}"
                "${lane} ${prebooking}"
                "${status} ${prebooking}"
                "${ops} ${kam}"
                `,
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        lane: {
            gridArea: lane,
        },
        prebooking: {
            gridArea: prebooking,
        },
        status: {
            gridArea: status,
        },
        ops: {
            gridArea: ops,
        },
        kam: {
            gridArea: kam,
        },
        general: {
            gridArea: general,
        },
    }
})

export default useStyles
