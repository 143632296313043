import React, {
    useCallback,
    useMemo,
} from 'react'
import moment from 'moment'
import {
    monthYearShortMask,
} from 'app/utils/date/monthYear'
import StatusHandler from 'app/shared-components/StatusHandler'

import useFilter from 'app/hooks/useFilter'
import DataContentWrapper from 'app/shared-components/DataContentWrapper'
import {
    AsyncMutationOptions,
} from 'app/types/request.types'
import useGetForecastSubmissionData from './hooks/useGetForecastSubmissionData'
import ForecastSpreadsheet from '../ForecastSpreadsheet'
import useBulkUpdateForecast from './hooks/useBulkUpdateForecast'
import {
    dataToSpreadsheetColumnsMap,
} from '../Forecast.utils'
import {
    ForecastDataSpreadsheetColumnsConfig,
} from '../Forecast.types'

const ForecastSubmission = () => {
    const filterOptions = useFilter()

    const {
        isError,
        error,
        refetch,
        loaded,
        forecastFields,
        actualDataFields,
    } = useGetForecastSubmissionData(filterOptions)

    const save = useBulkUpdateForecast()

    const onSave = useCallback((items, options: AsyncMutationOptions) => {
        return save(items.map((item) => {
            const {
                id, isConfirmed,
            } = item

            return {
                id,
                isConfirmed,
                forecasts: Array.from(forecastFields).map(([key]) => {
                    const momentDate = moment(key, monthYearShortMask)

                    return {
                        month: momentDate.month() + 1,
                        year: momentDate.year(),
                        units: item[key],
                    }
                }),
            }
        }, []), {
            ...options,
            onSuccess: options?.onSuccess || refetch,
        })
    }, [
        save,
        refetch,
        forecastFields,
    ])

    const getFieldsMapper = useCallback(dataToSpreadsheetColumnsMap, [])

    const tmpFields: ForecastDataSpreadsheetColumnsConfig = useMemo(() => {
        return [
            ...Array.from(forecastFields).sort().reverse().map(getFieldsMapper({
                disabled: false,
            })),
            ...Array.from(actualDataFields).sort().reverse().map(getFieldsMapper({
                disabled: true,
            })),
        ]
    }, [
        actualDataFields,
        forecastFields,
        getFieldsMapper,
    ])

    return (
        <StatusHandler
            isError={isError}
            isFetching={!loaded}
            error={error}
            isSuccess={loaded}
        >
            <DataContentWrapper isEmpty={!loaded}>
                <ForecastSpreadsheet
                    onSave={onSave}
                    tmpFields={tmpFields}
                    filterOptions={filterOptions}
                />
            </DataContentWrapper>
        </StatusHandler>

    )
}

export default ForecastSubmission
