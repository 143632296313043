import React, {
    useCallback, useMemo,
} from 'react'

import Card from 'app/shared-components/Card'
import RichTable from 'app/shared-components/RichTableReduxWrapper'
import useRequestTable from 'app/hooks/useRequestTable'
import useLoadFilters from 'app/Apps/DomainObject/hooks/useLoadFilters'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import SkyNetStepperButtons from 'app/shared-components/SkyNetStepper/SkyNetStepperButtons'
import useLabel from 'app/hooks/useLabel'

import requests from '../../PreBookings.requests'
import getColumns from './PreBookingAvailableLanes.columns'
import useStyles from './PreBookingAvailableLanes.styles'
import usePreBookingInfoContext from '../PreBookingInfoContext/PreBookingInfoContext'

const defaultFilter = {
    laneStatus: [
        'AWARDED',
        'IMPLEMENTED',
    ],
}

const PreBookingAvailableLanes = ({
    disabled,
}: {
    disabled: boolean,
}) => {
    const {
        classes,
    } = useStyles()
    const l = useLabel()
    const {
        activeStep,
        setActiveStep,
        setValue,
        initialValue,
        value,
        setIsEdited,
    } = usePreBookingInfoContext()

    const {
        onParamsChange,
        data: requestData,
        loadData,
    } = useRequestTable<{data:any}>({
        config: requests.getAvailableLanes,
        params: {},
        defaultValue: {
            filter: defaultFilter,
        },
        keepPreviousData: true,
        key: RequestKeys.getPreBookingAvailableLanes,
    })
    const injectLoadFilters = useLoadFilters(
        requests.getAvailableLanesFilter(),
    )

    const columns = useMemo(() => {
        return getColumns(l)
    }, [l])

    const columnsWithFilters = useMemo(() => {
        return injectLoadFilters(columns)
    }, [
        injectLoadFilters,
        columns,
    ])

    const handleOnSelectRow = useCallback((selectedRow) => {
        setIsEdited(true)
        const result = requestData?.data?.find((item) => {
            return selectedRow.includes(item.id)
        })

        setValue({
            ...initialValue,
            lane: {
                id: result.id,
            },
        })
    }, [
        requestData,
        setIsEdited,
        initialValue,
        setValue,
    ])

    const handleOnRowClick = useCallback((selectedRow) => {
        setIsEdited(true)
        const result = requestData?.data?.find((item) => {
            return item.id === selectedRow.id
        })

        setValue({
            ...initialValue,
            lane: {
                id: result.id,
            },
        })
    }, [
        requestData,
        setIsEdited,
        initialValue,
        setValue,
    ])

    const nextAction = useCallback(() => {
        setActiveStep(activeStep + 1)
    }, [
        setActiveStep,
        activeStep,
    ])

    const selectedRows: number[] = useMemo(() => {
        return [value?.lane?.id]
    }, [value])

    return (
        <div>
            <Card
                fullHeight
                contentClass={classes.cardContent}
                data-testid="prebooking-availableLanes-card"
            >
                <RichTable
                    configName="PreBookingAvailableLanes"
                    name="PreBookingAvailableLanes"
                    load={loadData}
                    uniqField="id"
                    onParamsChange={onParamsChange}
                    columns={columnsWithFilters}
                    className={classes.tableContent}
                    classNames={{
                        contentWrapper: classes.tableContentWrapper,
                    }}
                    onRowClick={handleOnRowClick}
                    disabled={disabled}
                    radioSelect
                    onSelectRow={handleOnSelectRow}
                    selectedRows={selectedRows}
                    filter={defaultFilter}
                />
            </Card>
            <SkyNetStepperButtons
                isValid
                disabled={disabled}
                nextAction={nextAction}
            />
        </div>
    )
}

export default PreBookingAvailableLanes
