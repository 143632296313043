import {
    DTOCardFormField, NestedField,
} from 'app/types/form.types'
import {
    ContactType,
} from 'app/types/enums'

import {
    DecisionMakingUnit,
    AttitudeToSkyCell,
    EngagementType,
    Department,
    LeadSource,
} from '../engagement.types'

type Fields = EngagementType & {
    company: {
        id: number,
    },
    contactCountry: {
        id: number,
    }
}

const getFields = (
    {
        getAllowedValues,
        exists,
        disabled,
        company,
        country,
    }:
    {
        getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
        exists: boolean,
        disabled: boolean,
        company?: {id: number}
        country?: {id: number}
    },
): DTOCardFormField<keyof Fields>[] => {
    return [{
        title: exists ? 'Engagement Details' : 'New Engagement',
        fields: [
            {
                id: 'column1',
                fields: [
                    {
                        name: 'contact',
                        componentName: 'ContactObjectSelector',
                        includeFilters: {
                            type: [ContactType.SKYCELL_COMMERCIAL],
                        },
                        required: true,
                        disabled: exists,
                        labelKey: 'Engagement.contactName',
                    },
                    ...disabled ? [{
                        name: 'attitudeToSkyCell',
                        componentName: 'AttitudeToSkyCell',
                        labelKey: 'Engagement.attitudeToSkyCell',
                        disabled,
                    }] as NestedField<keyof Fields>[] : [{
                        name: 'attitudeToSkyCell',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(AttitudeToSkyCell),
                        useDropdown: true,
                        required: true,
                        labelKey: 'Engagement.attitudeToSkyCell',
                    }] as NestedField<keyof Fields>[],
                    ...disabled ? [] : [{
                        name: 'leadSource',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(LeadSource),
                        useDropdown: true,
                        labelKey: 'Engagement.leadSource',
                    }] as NestedField<keyof Fields>[],
                ],
            },
            {
                id: 'column2',
                fields: [
                    {
                        name: 'company',
                        componentName: 'CompanyObjectSelector',
                        value: company,
                        disabled: true,
                        labelKey: 'Engagement.contactCompanyName',
                    },
                    {
                        name: 'contactCountry',
                        componentName: 'CountryDataSelector',
                        value: country,
                        disabled: true,
                        labelKey: 'Engagement.contactCountryName',
                    },
                    ...disabled ? [] : [{
                        name: 'department',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(Department),
                        useDropdown: true,
                        labelKey: 'Engagement.department',
                    }] as NestedField<keyof Fields>[],
                    {
                        name: 'reportingTo',
                        componentName: 'ContactObjectSelector',
                        includeFilters: {
                            companyId: company?.id ? [company?.id] : undefined,
                        },
                        labelKey: 'Engagement.reportingTo',
                    },
                    {
                        name: 'decisionMakingUnit',
                        componentName: 'EnumSelector',
                        useDropdown: true,
                        allowedValues: getAllowedValues(DecisionMakingUnit),
                        labelKey: 'Engagement.decisionMakingUnit',
                    },
                ],
            },
            {
                id: 'column3',
                fields: [
                    {
                        name: 'newsletterUnsubscribed',
                        componentName: 'Checkbox',
                        labelKey: 'Engagement.newsletterUnsubscribed',
                    },
                    {
                        name: 'gdprConsent',
                        componentName: 'Checkbox',
                        labelKey: 'Engagement.gdprConsent',
                    },
                    {
                        name: 'interestInSkyMind',
                        componentName: 'Checkbox',
                        labelKey: 'Engagement.interestInSkyMind',
                    },
                ],
            },
        ],
    }]
}

export default getFields
