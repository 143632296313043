import flatten from 'lodash/flatten'
import {
    ReactComponent as MasterDataIcon,
} from 'app/shared-components/images/icons/master_data.svg'
import {
    ReactComponent as OrdersIcon,
} from 'app/shared-components/images/icons/order.svg'
import {
    ReactComponent as ContainersIcon,
} from 'app/shared-components/images/icons/container.svg'
import {
    ReactComponent as SensorsIcon,
} from 'app/shared-components/images/icons/sensors.svg'
import {
    ReactComponent as FinanceIcon,
} from 'app/shared-components/images/icons/finance.svg'
import {
    ReactComponent as LocationIcon,
} from 'app/shared-components/images/icons/location.svg'
import {
    ReactComponent as LanesIcon,
} from 'app/shared-components/images/icons/lanes.svg'
import {
    ReactComponent as TransportPlannerIcon,
} from 'app/shared-components/images/icons/transport_planner.svg'
import {
    ReactComponent as RelationshipsManagementIcon,
} from 'app/shared-components/images/icons/relationships_management.svg'
import {
    ReactComponent as AccountManagementIcon,
} from 'app/shared-components/images/icons/account_management.svg'
import {
    ReactComponent as DamagesIcon,
} from 'app/shared-components/images/icons/damages.svg'
import {
    ReactComponent as ForecastIcon,
} from 'app/shared-components/images/icons/forecast.svg'

import authRoles from 'app/authRoles'
import {
    ActiveTab,
} from 'app/MainMenu/Panel/ActiveTab'
import {
    App,
} from 'app/types/common.enums'

const [accountReadAll] = authRoles.account_read_all
const [accountReadRestricted] = authRoles.account_read_restricted

const mainMenuConfig: ActiveTab[] = [
    {
        title: 'Contact Management',
        id: App.CONTACT_MANAGEMENT,
        subTitleText: 'Most of SkyCell\'s business partners get managed here.',
        icon: LocationIcon,
        className: 'iconSmall',
        links: [
            {
                title: 'Locations',
                id: 'locations',
                auth: authRoles.location_read,
                url: `/apps/${App.CONTACT_MANAGEMENT}/locations/all`,
            },
            {
                title: 'Billing Companies',
                id: 'billing-companies',
                auth: authRoles.location_read,
                url: `/apps/${App.CONTACT_MANAGEMENT}/billing-companies/all`,
            },
            {
                title: 'Companies',
                id: 'companies',
                auth: authRoles.location_read,
                url: `/apps/${App.CONTACT_MANAGEMENT}/companies/all`,
            },
            {
                title: 'Contacts',
                id: 'contacts',
                auth: authRoles.location_read,
                url: `/apps/${App.CONTACT_MANAGEMENT}/contacts/all`,
            },
            {
                title: 'Users',
                id: 'users',
                auth: authRoles.user_read,
                url: `/apps/${App.CONTACT_MANAGEMENT}/users/all`,
            },
        ],
    },
    {
        title: 'Relationships Management',
        id: App.RELATIONSHIPS_MANAGEMENT,
        subTitleText: 'Manage relationships with business partners here.',
        icon: RelationshipsManagementIcon,
        className: 'iconSmall',
        links: [
            {
                title: 'Activity Records',
                id: 'activity-records',
                auth: [
                    ...authRoles.relationships_ops,
                    ...authRoles.relationships_sales,
                ],
                url: `/apps/${App.RELATIONSHIPS_MANAGEMENT}/activity-records/all`,
            },
            {
                title: 'Engagements',
                id: 'engagements',
                auth: [...authRoles.relationships_sales],
                url: `/apps/${App.RELATIONSHIPS_MANAGEMENT}/engagements/all`,
            },
        ],
    },
    {
        title: 'Account Management',
        id: App.ACCOUNT_MANAGEMENT,
        subTitleText: 'Develop business with existing customers and create new ones.',
        icon: AccountManagementIcon,
        className: 'iconLarge',
        links: [
            {
                title: 'Dashboard',
                id: 'kam-dashboard',
                auth: authRoles.account_quote_lane_award_reject,
                url: `/apps/${App.ACCOUNT_MANAGEMENT}/kam-dashboard/all`,
            },
            {
                title: 'Accounts',
                id: 'accounts',
                auth: [
                    accountReadAll,
                    accountReadRestricted,
                ],
                url: `/apps/${App.ACCOUNT_MANAGEMENT}/accounts/all`,
            },
            {
                title: 'Regional Groups',
                id: 'regional-groups',
                auth: [
                    accountReadAll,
                    accountReadRestricted,
                ],
                url: `/apps/${App.ACCOUNT_MANAGEMENT}/regional-groups/all`,
            },
            {
                title: 'Opportunities',
                id: 'opportunities',
                auth: [
                    accountReadAll,
                    accountReadRestricted,
                ],
                url: `/apps/${App.ACCOUNT_MANAGEMENT}/opportunities/all`,
            },
            {
                title: 'Lanes',
                id: 'lanes',
                auth: [
                    accountReadAll,
                    accountReadRestricted,
                ],
                url: `/apps/${App.ACCOUNT_MANAGEMENT}/lanes/all`,
            },
            {
                title: 'Quotes',
                id: 'quotes',
                auth: [
                    accountReadAll,
                    accountReadRestricted,
                ],
                url: `/apps/${App.ACCOUNT_MANAGEMENT}/quotes/all`,
            },
            {
                title: 'Pre Bookings',
                id: 'pre-bookings',
                auth: authRoles.pre_booking_read,
                url: `/apps/${App.ACCOUNT_MANAGEMENT}/pre-bookings/all`,
            },
        ],
    },
    {
        title: 'Pricing',
        id: App.PRICING,
        subTitleText: 'Access pricing relevant information that SkyCell maintains with it\'s customers.',
        icon: LanesIcon,
        className: 'iconMedium',
        links: [
            {
                title: 'Dashboard',
                id: 'pricing-dashboard',
                auth: authRoles.account_quote_lane_group,
                url: `/apps/${App.PRICING}/pricing-dashboard/all`,
            },
            {
                title: 'Contract Basis',
                id: 'contract-basis',
                auth: authRoles.contract_basis_read,
                url: `/apps/${App.PRICING}/contract-basis/all`,
            },
            {
                title: 'Lane Groups',
                id: 'lane-groups',
                auth: authRoles.contract_basis_read,
                url: `/apps/${App.PRICING}/lane-groups/all`,
            },
            {
                title: 'Lanes',
                id: 'lanes',
                auth: authRoles.contract_basis_read,
                url: `/apps/${App.PRICING}/lanes/all`,
            },
            {
                title: 'Al Logistic Pricing',
                id: 'al-logistic-pricing',
                auth: authRoles.contract_basis_read,
                url: `/apps/${App.PRICING}/al-logistic-pricing/all`,
            },
            {
                title: 'Al Lease Pricing',
                id: 'al-lease-pricing',
                auth: authRoles.contract_basis_read,
                url: `/apps/${App.PRICING}/al-lease-pricing/all`,
            },
            {
                title: 'Quotes',
                id: 'quotes',
                auth: authRoles.contract_basis_read,
                url: `/apps/${App.PRICING}/quotes/all`,
            },
        ],
    },
    {
        title: 'Forecast',
        id: App.FORECAST,
        subTitleText: 'Check and create forecast data for future shipments.',
        icon: ForecastIcon,
        className: 'iconMedium',
        links: [
            {
                title: 'Short Term Forecast',
                id: 'shortterm-forecasts',
                auth: flatten([
                    authRoles.forecast_read,
                    authRoles.forecast_super,
                ]),
                url: `/apps/${App.FORECAST}/shortterm-forecasts/all`,
            },
            {
                title: 'Forecast Submission',
                id: 'forecast-submission',
                auth: flatten([
                    authRoles.forecast_update,
                    authRoles.forecast_super,
                ]),
                url: `/apps/${App.FORECAST}/forecast-submission`,
            },
        ],
    },
    {
        title: 'Order Management',
        id: App.ORDER_MANAGEMENT,
        subTitleText: 'Create and update customer and internal orders.',
        icon: OrdersIcon,
        links: [
            {
                title: 'Daily Ops',
                id: 'daily-ops',
                auth: authRoles.order_read,
                url: `/apps/${App.ORDER_MANAGEMENT}/daily-ops/all`,
            },
            {
                title: 'Pooling',
                id: 'pooling',
                auth: authRoles.order_read,
                url: `/apps/${App.ORDER_MANAGEMENT}/pooling/all`,
            },
            {
                title: 'Open Bookings',
                id: 'open-bookings',
                auth: authRoles.order_read,
                url: `/apps/${App.ORDER_MANAGEMENT}/open-bookings/all`,
            },
            {
                title: 'Lanes',
                id: 'lanes',
                auth: authRoles.contract_basis_read,
                url: `/apps/${App.ORDER_MANAGEMENT}/lanes/all`,
            },
            {
                title: 'Customer Orders',
                id: 'customer-orders',
                auth: authRoles.order_read,
                url: `/apps/${App.ORDER_MANAGEMENT}/customer-orders/all`,
            },
            {
                title: 'Internal Orders',
                id: 'internal-orders',
                auth: authRoles.order_read,
                url: `/apps/${App.ORDER_MANAGEMENT}/internal-orders/all`,
            },
            {
                title: 'Pre Bookings',
                id: 'pre-bookings',
                auth: authRoles.pre_booking_read,
                url: `/apps/${App.ORDER_MANAGEMENT}/pre-bookings/all`,
            },
        ],
    },
    {
        title: 'Container Management',
        id: App.CONTAINERS,
        subTitleText: 'Manage SkyCell\'s container fleet and check the status of service centers around the world.',
        icon: ContainersIcon,
        className: 'iconLarge',
        links: [
            {
                title: 'Containers',
                id: 'containers',
                auth: authRoles.container_read,
                url: `/apps/${App.CONTAINERS}/containers/all`,
            },
            {
                title: 'Container Selection',
                id: 'containers-selection',
                auth: authRoles.container_read,
                url: `/apps/${App.CONTAINERS}/containers-selection/all`,
            },
            {
                title: 'Container Types',
                id: 'container-type',
                auth: authRoles.container_read,
                url: `/apps/${App.CONTAINERS}/container-type`,
            },
        ],
    },
    {
        title: 'Loggers And Gateways',
        id: 'logger',
        subTitleText: 'Check the logger inventory and the gateways feeding data into our system.',
        icon: SensorsIcon,
        links: [
            {
                title: 'Loggers',
                id: 'loggers',
                auth: authRoles.logger_read,
                url: '/apps/loggers/all',
            },
            {
                title: 'Gateways',
                id: 'gateways',
                auth: authRoles.gateway_read,
                url: '/apps/gateways/all',
            },
        ],
    },
    {
        title: 'Billing',
        id: App.FINANCE,
        subTitleText: 'Prepare invoices resulting from shipments done for customers.',
        icon: FinanceIcon,
        className: 'iconMedium',
        links: [
            {
                title: 'Invoices',
                id: 'invoices',
                auth: authRoles.outgoing_invoice_read,
                url: `/apps/${App.FINANCE}/invoices/all`,
            },
            {
                title: 'FX Rates',
                id: 'fx-rates',
                auth: authRoles.fxrate_read,
                url: `/apps/${App.FINANCE}/fx-rates/all`,
            },
        ],
    },
    {
        title: 'Transport Planner',
        id: App.TRANSPORT_PLANNER,
        subTitleText: 'Simulate a transport to see if the planned lane does not lead to excursions.',
        icon: TransportPlannerIcon,
        className: 'iconMedium',
        links: [
            {
                title: 'New Temperature Simulation',
                id: 'simulation',
                auth: authRoles.transport_planner_create,
                url: `/apps/${App.TRANSPORT_PLANNER}/simulation`,
            },
            {
                title: 'Saved Temperature Simulations',
                id: 'savedSimulations',
                auth: authRoles.transport_planner_read,
                url: `/apps/${App.TRANSPORT_PLANNER}/savedSimulations`,
            },
            {
                title: `CO${String.fromCharCode(8322)} Calculation`,
                id: 'co2calculation',
                auth: authRoles.transport_planner_read,
                url: `/apps/${App.TRANSPORT_PLANNER}/co2calculation`,
            },
        ],
    },
    {
        title: 'Damage Tracking',
        id: App.DAMAGE_TRACKING,
        subTitleText: 'Manage damages on containers and check the status of repairs.',
        icon: DamagesIcon,
        className: 'iconMedium',
        links: [{
            title: 'Damages',
            id: 'damages',
            auth: authRoles.damage_tracking_read,
            url: `/apps/${App.DAMAGE_TRACKING}/damages/all`,
        }],
    },
    {
        title: 'Master Data',
        id: App.MASTERDATA,
        subTitleText: 'Update all kind of master data, ranging from countries to airports and seaports.',
        icon: MasterDataIcon,
        className: 'iconMedium',
        links: [{
            title: 'Countries',
            id: 'countries',
            auth: authRoles.master_read,
            url: `/apps/${App.MASTERDATA}/countries/all`,
        }],
    },
]

export default mainMenuConfig
