import React from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'

import ContactForm from '../ContactForm/ContactForm'
import useGetContact from '../hooks/useGetContact'

const ContactOverview = ({
    id,
}: { id: number }) => {
    const {
        data,
        isFetching,
        isDataReady,
        isError,
        error,
    } = useGetContact(id)

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <ContactForm
                data={data}
                disabled
            />
        </StatusHandler>
    )
}

export default ContactOverview
