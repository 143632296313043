import React, {
    useCallback,
} from 'react'
import isObject from 'lodash/isObject'
import isEqual from 'lodash/isEqual'
import isArray from 'lodash/isArray'
import {
    useHistory,
} from 'react-router-dom'
import StatusHandler from 'app/shared-components/StatusHandler'
import useFilter from 'app/hooks/useFilter'
import {
    AsyncMutationOptions,
} from 'app/types/request.types'
import useGetOpportunities from '../hooks/useGetOpportunities'
import OpportunitiesSpreadSheet from '../OpportunitiesSpreadSheet'
import useOpportunitiesBulkEdit from './hooks/useOpportunitiesBulkEdit'
import {
    OpportunityType,
} from '../opportunities.types'

const OpportunitiesBulkEdit = ({
    backUrl,
}: {
    backUrl: string
}) => {
    const filterOptions = useFilter()

    const {
        isError,
        loaded,
        error,
    } = useGetOpportunities(filterOptions)

    const history = useHistory()

    const onSuccess = useCallback(() => {
        history.push(backUrl)
    }, [
        history,
        backUrl,
    ])

    const opportunitiesEdit = useOpportunitiesBulkEdit()

    const onSave = useCallback((
        opportunities: OpportunityType[],
        options?: AsyncMutationOptions,
    ) => {
        const editedItems = opportunities.reduce((acc, opp) => {
            const uneditedData = (filterOptions?.filteredData || []).find((el) => {
                return opp.id === el.id
            })

            if (Object.keys(opp).some((key) => {
                if (isArray(opp[key])) {
                    return !isEqual(opp[key], uneditedData[key])
                }
                if (isObject(opp[key])) {
                    return !isEqual(opp[key]?.id, uneditedData[key]?.id)
                }
                return !isEqual(opp[key], uneditedData[key])
            })) {
                return [
                    ...acc,
                    opp,
                ]
            }
            return acc
        }, [])

        opportunitiesEdit(editedItems, {
            ...options,
            onSuccess: options?.onSuccess || onSuccess,
        })
    }, [
        filterOptions?.filteredData,
        onSuccess,
        opportunitiesEdit,
    ])

    return (
        <StatusHandler
            isError={isError}
            isFetching={!loaded}
            error={error}
            isSuccess={loaded}
        >
            <OpportunitiesSpreadSheet
                onSave={onSave}
                onCancel={onSuccess}
                filterOptions={filterOptions}
            />
        </StatusHandler>

    )
}

export default OpportunitiesBulkEdit
