import React, {
    useCallback,
    useMemo,
} from 'react'
import isEmpty from 'lodash/isEmpty'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import {
    PreBookingStatus,
} from 'app/Apps/PreBooking/PreBooking.types'
import StatusHandler from 'app/shared-components/StatusHandler'
import useLabel from 'app/hooks/useLabel'
import useLoadFilters from 'app/Apps/DomainObject/hooks/useLoadFilters'
import requests from 'app/Apps/PreBooking/PreBookings.requests'
import useGetPrebookings from '../hooks/useGetPrebookings'
import getColumns from './closePrebookingTable.columns'

const ClosePrebookingTable = ({
    selectPrebooking,
    selected,
}: Readonly<{
    selectPrebooking: React.Dispatch<React.SetStateAction<number>>,
    selected: number
}>) => {
    const l = useLabel()
    const {
        loadData,
        isDataReady,
        isFetching,
        isError,
        error,
        onParamsChange,
    } = useGetPrebookings({
        preBookingStatus: [PreBookingStatus.PENDING],
    }, isEmpty(selected))

    const columns = useMemo(() => {
        return getColumns({
            l,
        })
    }, [l])

    const onSelectRow = useCallback((val: number[]) => {
        if (val.length) {
            selectPrebooking(val?.[0])
        }
    }, [selectPrebooking])

    const injectLoadFilters = useLoadFilters(
        {
            customUrl: requests.filterRequest({
                params: {},
            }).url,
        },
    )

    const columnsWithFilters = useMemo(() => {
        return injectLoadFilters(columns)
    }, [
        injectLoadFilters,
        columns,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <RichTable
                onParamsChange={onParamsChange}
                configName="ClosePrebookingTable"
                load={loadData}
                name=""
                columns={columnsWithFilters}
                uniqField="id"
                radioSelect
                onSelectRow={onSelectRow}
                onRowClick={onSelectRow}
                selectedRows={[selected]}
            />
        </StatusHandler>
    )
}

export default ClosePrebookingTable
