import React, {
    useCallback, useMemo,
    useState,
} from 'react'

import ConfirmationDialog from 'app/shared-components/ConfirmationDialog'
import RichTable from 'app/shared-components/RichTableReduxWrapper'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import useRequestTable from 'app/hooks/useRequestTable'
import {
    TableResponse,
} from 'app/types/request.types'
import {
    OpportunityTableType,
} from 'app/Apps/AccountManagement/Opportunities/opportunities.types'
import request from 'app/Apps/AccountManagement/Opportunities/opportunities.request'
import useLabel from 'app/hooks/useLabel'
import AccountSelector from 'app/shared-components/AccountSelector'
import InputSingleline from 'app/shared-components/InputSingleline'

import {
    LaneGroupTemperatureRange,
} from 'app/types/enums'

import useAssignmentToOpportunity from './hooks/useAssignmentToOpportunity'
import useStyles from './SelectOpportunityDialog.style'
import getColumns from './opportunityTable.columns'
import {
    UnassignedToOpportunity,
} from '../../KamDashboard.types'

type Props = {
    open: boolean,
    setOpen: (value: boolean) => void
    onSuccess: () => void,
    setIsAssigned: (isAssigned: boolean) => void,
    temperatureRange: LaneGroupTemperatureRange,
    selectedLanes: UnassignedToOpportunity[],
}

const DIALOG_TITLE = 'SELECT OPPORTUNITY'

const SelectOpportunityDialog = ({
    open,
    setOpen,
    onSuccess,
    temperatureRange,
    selectedLanes,
    setIsAssigned,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const l = useLabel()

    const [
        value,
        setValue,
    ] = useState<{
        opportunity?: number[],
        account?: {id: number},
    }>({})

    const {
        loadData,
        onParamsChange,
    } = useRequestTable<TableResponse<OpportunityTableType>>({
        config: request.FilterRequestAll.requestFn,
        params: {},
        key: RequestKeys.getOpportunitiesAll,
    })

    const onChangeOpportunity = (val: number[]) => {
        setValue({
            ...value,
            opportunity: val,
        })
    }

    const onChangeAccount = (val: { id: number }) => {
        setValue({
            ...value,
            account: val,
        })
    }

    const columns = useMemo(() => {
        return getColumns({
            l,
        })
    }, [l])

    const onAssigned = useCallback((): void => {
        setOpen(false)
        setValue(undefined)
        onSuccess()
    }, [
        onSuccess,
        setOpen,
    ])

    const {
        isPending: isLoading,
        mutate,
    } = useAssignmentToOpportunity(onAssigned)

    const handleClose = useCallback((action: boolean) => {
        if (!action) {
            setOpen(false)
            setValue(undefined)

            return
        }

        setIsAssigned(true)
        mutate({
            lanes: selectedLanes.map(({
                id,
            }) => {
                return {
                    id,
                }
            }),
            opportunity: {
                id: value?.opportunity?.[0],
            },
        })
    }, [
        mutate,
        selectedLanes,
        setIsAssigned,
        setOpen,
        value?.opportunity,
    ])

    const selectedLanesStr: string = useMemo(() => {
        return selectedLanes?.map(({
            laneNumber,
            originAirport,
            destinationAirport,
        }) => {
            return `${laneNumber} | ${originAirport}-${destinationAirport}`
        })
            .join(', ')
    }, [selectedLanes])

    return (
        <ConfirmationDialog
            open={open}
            handleClose={handleClose}
            dialogTitle={DIALOG_TITLE}
            positiveLabel="save"
            negativeLabel="cancel"
            classNames={{
                paper: classes.paper,
            }}
            isLoading={isLoading}
            positiveLabelDisabled={!value?.opportunity?.length}
        >
            <div>
                <div className={classes.wrapper}>
                    <AccountSelector
                        name="account"
                        value={value?.account}
                        onChange={onChangeAccount}
                        title={l('AccountLane.accountCompanyName')}
                        required
                    />
                    <InputSingleline
                        title={l('KamDashboard.lanesSelected')}
                        value={selectedLanesStr}
                        name="selectedLanes"
                        disabled
                    />
                </div>
                {value?.account && (
                    <RichTable
                        configName="OpportunityTable"
                        name="Opportunities"
                        load={loadData}
                        columns={columns}
                        className={classes.tableContent}
                        classNames={{
                            contentWrapper: classes.tableContentWrapper,
                        }}
                        filter={{
                            temperatureRange: [temperatureRange],
                            accountId: [value?.account?.id],
                        }}
                        onParamsChange={onParamsChange}
                        onSelectRow={onChangeOpportunity}
                        selectedRows={value?.opportunity}
                        radioSelect
                    />
                )}
            </div>
        </ConfirmationDialog>
    )
}

export default SelectOpportunityDialog
