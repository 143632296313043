import {
    Domain,
    DomainUIOnly,
    FieldConfig,
    SkyNetSpreadSheetCellType,
    SkyNetSpreadSheetConfigType,
    TypeaheadParams,
} from 'app/shared-components/SkyNetSpreadSheet'

import {
    OpportunityFrequency,
    OpportunityPalletType,
    RegionFirstLevel,
} from 'app/types/enums'
import {
    OpportunityType, Packaging,
} from '../opportunities.types'

const OpportunitiesSpreadSheetConfig = ({
    getLabel,
    allowedTempRanges,
    getAllowedValues,
    regionalGroupParams,
    airlineParams,
    originAirportParams,
    destinationAirportParams,
    editMode = false,
    onRegionalGroupChange,
    noOriginRegion,
    noDestinationRegion,
    originRegionFilter,
    destinationRegionFilter,
    isRegionalGroupDisabled,
}: {
    getLabel: (...args: any[]) => string,
    allowedTempRanges: Record<string, string>,
    getAllowedValues: (type: Record<string, string>) => Record<string, string>,
    regionalGroupParams: TypeaheadParams,
    airlineParams: TypeaheadParams,
    originAirportParams: TypeaheadParams,
    destinationAirportParams: TypeaheadParams,
    editMode?: boolean,
    onRegionalGroupChange: ({
        id: number,
    }) => any,
    noOriginRegion: (ot: OpportunityType) => boolean,
    noDestinationRegion: (ot: OpportunityType) => boolean,
    originRegionFilter: (i: Record<string, any>) => Record<string, any>,
    destinationRegionFilter: (i: Record<string, any>) => Record<string, any>,
    isRegionalGroupDisabled: (i: Record<string, any>) => boolean
}): SkyNetSpreadSheetConfigType<keyof OpportunityType> => {
    let fields: FieldConfig<keyof OpportunityType>[] = [
        {
            name: 'regionalGroup',
            cellConfig: {
                type: SkyNetSpreadSheetCellType.TYPEAHEAD,
                onChangeOverride: onRegionalGroupChange,
            },
            ...regionalGroupParams,
            domain: DomainUIOnly.REGIONAL_GROUP,
            title: getLabel('RegionalGroups.regionalGroupNumber'),
            width: 100,
            required: true,
            disabled: isRegionalGroupDisabled,
        },
        {
            name: 'temperatureRange',
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            disabled: true,
            allowedValues: allowedTempRanges,
            required: true,
            title: getLabel('Opportunities.temperatureRange'),
            width: 100,
        },
        {
            name: 'frequency',
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            allowedValues: getAllowedValues(OpportunityFrequency),
            title: getLabel('Opportunity.frequency'),
            width: 100,
        },
        {
            name: 'airline',
            title: getLabel('Opportunity.airline'),
            width: 100,
            ...airlineParams,
            domain: Domain.COMPANY,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.TYPEAHEAD,
            },
        },
        {
            name: 'businessStart',
            title: getLabel('Opportunity.businessStart'),
            width: 125,
            format: 'DD.MM.YYYY HH:mm',
            cellConfig: {
                type: SkyNetSpreadSheetCellType.DATETIME,
            },
        },
        {
            name: 'originRegion',
            title: getLabel('RegionalGroups.originRegion'),
            width: 100,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            allowedValues: getAllowedValues(RegionFirstLevel),
            disabled: true,
        },
        {
            name: 'reprOriginAirport',
            title: getLabel('Opportunity.reprOriginAirport'),
            required: true,
            ...originAirportParams,
            domain: Domain.AIRPORT,
            width: 110,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.TYPEAHEAD,
                conditionalType: [[
                    noOriginRegion,
                    SkyNetSpreadSheetCellType.NO_VALUE,
                ]],
            },
            applyFilters: originRegionFilter,
        },
        {
            name: 'destinationRegion',
            title: getLabel('RegionalGroups.destinationRegion'),
            width: 100,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            allowedValues: getAllowedValues(RegionFirstLevel),
            disabled: true,
        },
        {
            name: 'reprDestinationAirport',
            ...destinationAirportParams,
            title: getLabel('Opportunity.reprDestinationAirport'),
            required: true,
            domain: Domain.AIRPORT,
            width: 110,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.TYPEAHEAD,
                conditionalType: [[
                    noDestinationRegion,
                    SkyNetSpreadSheetCellType.NO_VALUE,
                ]],
            },
            applyFilters: destinationRegionFilter,
        },
        {
            name: 'annualPalletVolume',
            title: getLabel('Opportunity.annualPalletVolume'),
            required: true,
            width: 50,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.NUMBER,
            },
        },
        {
            name: 'competitorsPackaging',
            allowedValues: getAllowedValues(Packaging),
            required: true,
            title: getLabel('Opportunity.competitorsPackaging'),
            cellConfig: {
                type: SkyNetSpreadSheetCellType.MULTIPLE_SELECT,
            },
            width: 100,
        },
        {
            name: 'currentPackagingSolution',
            allowedValues: getAllowedValues(Packaging),
            required: true,
            title: getLabel('Opportunity.currentPackagingSolution'),
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            width: 100,
            resizable: true,
        },
        {
            name: 'palletType',
            allowedValues: getAllowedValues(OpportunityPalletType),
            title: getLabel('Opportunity.palletType'),
            required: true,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.SELECT,
            },
            width: 100,
        },
        {
            name: 'validationNeeded',
            title: getLabel('Opportunity.validationNeeded'),
            width: 60,
            cellConfig: {
                type: SkyNetSpreadSheetCellType.CHECKBOX,
            },
        },
    ]

    if (editMode) {
        fields = [
            ...fields,
            {
                name: 'id',
                width: 1,
                hidden: true,
                cellConfig: {
                    type: SkyNetSpreadSheetCellType.ID,
                },
            },
        ] as FieldConfig<keyof OpportunityType>[]
    }

    return {
        id: 'Opportunities',
        headerRow: true,
        rowsCounter: true,
        headerHeight: 60,
        addRowMenuOption: !editMode,
        enableRangeSelection: true,
        enableRowSelection: true,
        stickyTopRows: 1,
        filterable: editMode,
        emptyRows: editMode ? 0 : 10,
        rowsLimit: 25,
        initialFocusLocation: {
            columnId: 'account',
            rowId: 0,
        },
        fields,
    }
}

export default OpportunitiesSpreadSheetConfig
