import React, {
    useCallback,
    useMemo,
    useState,
} from 'react'

import SkyNetStepperButtons from 'app/shared-components/SkyNetStepper/SkyNetStepperButtons'
import useValidation from 'app/hooks/useValidation'
import DomainObjectForm from 'app/shared-components/DomainObjectForm'
import useEnumValues from 'app/hooks/useEnumValues'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'

import usePreBookingInfoContext from '../PreBookingInfoContext/PreBookingInfoContext'
import usePreBookingUpdate from './hooks/usePreBookingUpdate'

import getFields, {
    ReasonForChangeValue,
} from './ReasonForChangeForm.fields'
import useStyles from './ReasonForChangeForm.styles'

const ReasonForChangeForm = ({
    disabled,
    nextAction,
}:{
    disabled: boolean,
    nextAction: () => void,
}) => {
    const {
        classes,
    } = useStyles()
    const {
        activeStep,
        setActiveStep,
        value: preBookingValues,
        setIsEdited,
    } = usePreBookingInfoContext()

    const getAllowedValues = useEnumValues()
    const [
        value,
        setValue,
    ] = useState<ReasonForChangeValue>({
        reasonForChange: undefined,
    })
    const [
        saving,
        setSaving,
    ] = useState<boolean>(false)
    const onSuccessUpdate = useCallback(() => {
        setSaving(false)
        nextAction()
    }, [nextAction])

    const updatePreBooking = usePreBookingUpdate({
        data: preBookingValues,
        onSuccess: onSuccessUpdate,
    })

    const backAction = useCallback(() => {
        setActiveStep(activeStep - 1)
    }, [
        setActiveStep,
        activeStep,
    ])

    const fields = useMemo(() => {
        return getFields({
            getAllowedValues,
        })
    }, [getAllowedValues])

    const fieldsWithClass = useFieldsWithClassName(fields, classes)

    const {
        isValid,
    } = useValidation({
        fields,
        values: value,
    })

    const handleNextAction = useCallback(async () => {
        setSaving(true)
        setIsEdited(false)
        updatePreBooking(value.reasonForChange)
        setSaving(false)
    }, [
        updatePreBooking,
        value.reasonForChange,
        setIsEdited,
    ])

    return (
        <div>
            <DomainObjectForm
                name="PreBookingReasonForChange"
                className={classes.gridWrapper}
                value={value}
                fields={fieldsWithClass}
                onChange={setValue}
                disabled={disabled}
            />
            <SkyNetStepperButtons
                isValid={isValid && !saving}
                disabled={disabled}
                canGoBack
                backAction={backAction}
                nextLabel="Update"
                nextAction={handleNextAction}
            />
        </div>
    )
}

export default ReasonForChangeForm
