import {
    RichTableColumns,
} from 'app/types/richtable.types'
import {
    LaneStatus,
} from 'app/Apps/AccountManagement/AccountLanes/AccountLanes.types'
import {
    LeaseType,
} from 'app/types/enums'
import {
    FilterType,
} from 'app/types/common.enums'

import {
    PreBookingAvailableLanes,
} from '../../PreBooking.types'

const columns = (l:(l:string) => string): RichTableColumns<keyof PreBookingAvailableLanes>[] => {
    return [
        {
            id: 'laneNumber',
            mapCellProps: 'laneNumber',
            headerProps: {
                children: l('Prebooking.lane'),
            },
        },
        {
            id: 'laneStatus',
            mapCellProps: 'laneStatus',
            headerProps: {
                children: l('LaneQuoteRequest.selectedLaneStatus'),
            },
            componentName: 'EnumValue',
            allowedValuesEnum: LaneStatus,
        },
        {
            id: 'customerExecutionLocationName',
            mapCellProps: 'customerExecutionLocationName',
            headerProps: {
                children: l('Lane.customerExecution'),
            },
            filterField: 'customerExecutionLocationName',
            filterType: FilterType.Array,
        },
        {
            id: 'accountCompanyCompanyName',
            mapCellProps: 'accountCompanyCompanyName',
            headerProps: {
                children: l('Prebooking.pricingAccount'),
            },
            filterField: 'accountCompanyCompanyName',
            filterType: FilterType.Array,
        },
        {
            id: 'consigneeLocationName',
            mapCellProps: 'consigneeLocationName',
            headerProps: {
                children: l('Lane.consignee'),
            },
            filterField: 'consigneeLocationName',
            filterType: FilterType.Array,
        },
        {
            id: 'leaseType',
            mapCellProps: 'leaseType',
            headerProps: {
                children: l('LaneGroup.leaseType'),
            },
            componentName: 'EnumValue',
            allowedValuesEnum: LeaseType,
            filterField: 'leaseType',
            filterType: FilterType.Array,
        },
        {
            id: 'handoverPointLocationName',
            mapCellProps: 'handoverPointLocationName',
            headerProps: {
                children: l('LaneGroup.handoverPoint'),
            },
            filterField: 'handoverPointLocationName',
            filterType: FilterType.Array,
        },
        {
            id: 'collectionDropoffPointLocationName',
            mapCellProps: 'collectionDropoffPointLocationName',
            headerProps: {
                children: l('Order.collectionDropoffPoint'),
            },
            filterField: 'collectionDropoffPointLocationName',
            filterType: FilterType.Array,
        },
        {
            id: 'originAirportIataCode',
            mapCellProps: 'originAirportIataCode',
            headerProps: {
                children: l('LaneGroupView.from'),
            },
            filterField: 'originAirportIataCode',
            filterType: FilterType.Array,
        },
        {
            id: 'destAirportIataCode',
            mapCellProps: 'destAirportIataCode',
            headerProps: {
                children: l('LaneGroupView.to'),
            },
            filterField: 'destAirportIataCode',
            filterType: FilterType.Array,
        },
        {
            id: 'tempRange',
            mapCellProps: 'tempRange',
            headerProps: {
                children: l('LaneGroupView.tempRange'),
            },
            filterField: 'tempRange',
            filterType: FilterType.Array,
        },
    ]
}

export default columns
