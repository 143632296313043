import {
    useEffect,
} from 'react'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import useRequestTable from 'app/hooks/useRequestTable'

import {
    RequestKeys,
} from 'app/hooks/useRequest'
import requests from 'app/Apps/Forecast/Forecast.requests'
import {
    FilterOptions,
} from 'app/hooks/useFilter'
import useFirstLoading from 'app/hooks/useFirstLoading'
import {
    ForecastType,
} from '../../Forecast.types'
import useConvertToSpreadsheetData from './useConvertToSpreadsheetData'

export default (
    filterOptions: FilterOptions,
) => {
    const {
        forecastFields,
        actualDataFields,
        convertToSpreadsheetData,
    } = useConvertToSpreadsheetData()

    const {
        isDataReady,
        refetch,
        data,
        isError,
        error,
        isFetching,
    } = useRequestTable<{data: ForecastType[]}>({
        key: RequestKeys.getForecastSubmissionData,
        params: {
            includeFilters: isEmpty(filterOptions?.filter) ? undefined : filterOptions?.filter,
        },
        config: requests.getAll,
        keepPreviousData: true,
        enabled: true,
    })

    useEffect(() => {
        if (isDataReady) {
            const spreadsheetData = convertToSpreadsheetData(data?.data)

            if (!isEqual(spreadsheetData, filterOptions.filteredData)) {
                filterOptions.setFilteredData(convertToSpreadsheetData(data?.data))
            }
        }
    }, [
        convertToSpreadsheetData,
        data,
        filterOptions,
        isDataReady,
    ])

    const loaded = useFirstLoading(isFetching)

    return {
        isError,
        error,
        isDataReady,
        refetch,
        loaded,
        forecastFields,
        actualDataFields,
    }
}
