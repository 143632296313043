import React from 'react'

import Card from 'app/shared-components/Card'

import ContactChangeSummary from '../ContactChangeSummary'

const ChangeHistory = ({
    id,
}: { id: number }) => {
    return (
        <Card
            title="Change History"
        >
            <ContactChangeSummary id={id} />
        </Card>
    )
}

export default ChangeHistory
