import {
    RequestConfig, UseRequestConfig,
} from 'app/types/request.types'
import {
    OpportunityType,
} from './opportunities.types'

type Request = {
    domainName: string,
    GetOpportunityById: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
    FilterRequest: ({
        params,
    }: {
        params?: Record<string, any>
    }) => RequestConfig,
    archive: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
    restore: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
    UpdateOpportunity: ({
        opportunityId,
    }) => RequestConfig,
    UpdateContainerInformation: ({
        opportunityId,
    }) => RequestConfig,
    CreateOpportunity: () => RequestConfig,
    modifications: ({
        id,
    }: {
        id: number
    }) => RequestConfig,
    bulkCreate: ({
        data,
    }: {
        data: OpportunityType[],
    }) => RequestConfig,
    bulkEdit: ({
        data,
    }: {
        data: OpportunityType[]
    }) => RequestConfig,
    getAll: (data) => RequestConfig,
    filterSpreadsheetCount: (fieldName: {
        fieldName: string,
    }) => RequestConfig,
    FilterRequestAll: UseRequestConfig,
}

const request: Request = {
    domainName: 'Opportunity',
    GetOpportunityById: ({
        id,
    }) => {
        return {
            url: `opportunity/${id}`,
        }
    },
    UpdateOpportunity: ({
        opportunityId,
    }) => {
        return {
            url: `opportunity/${opportunityId}`,
            method: 'PUT',
        }
    },
    UpdateContainerInformation: ({
        opportunityId,
    }) => {
        return {
            url: `opportunity/${opportunityId}/container-info`,
            method: 'PATCH',
        }
    },
    CreateOpportunity: () => {
        return {
            url: 'opportunity',
            method: 'POST',
        }
    },
    FilterRequestAll: {
        requestFn: ({
            params,
        }) => {
            return {
                url: 'opportunity/filter-request/all',
                method: 'POST',
                ...params,
            }
        },
    },
    FilterRequest: ({
        params,
    }) => {
        return {
            url: 'opportunity/filter-request/all',
            method: 'POST',
            ...params,
        }
    },
    modifications: ({
        id,
    }) => {
        return {
            url: `opportunity/${id}/modifications`,
            method: 'GET',
        }
    },
    bulkCreate: ({
        data,
    }) => {
        return {
            url: 'opportunity/bulk',
            method: 'POST',
            data,
        }
    },
    bulkEdit: ({
        data,
    }) => {
        return {
            url: 'opportunity/bulk',
            method: 'PATCH',
            data,
        }
    },
    getAll: (params) => {
        return {
            url: 'opportunity/filter-request/spreadsheet',
            method: 'POST',
            ...params,
        }
    },
    archive: ({
        id,
    }) => {
        return {
            url: `opportunity/${id}/archive`,
            method: 'PATCH',
        }
    },
    restore: ({
        id,
    }) => {
        return {
            url: `opportunity/${id}/restore`,
            method: 'PATCH',
        }
    },
    filterSpreadsheetCount: ({
        fieldName,
    }) => {
        return {
            url: `opportunity/filter-request/spreadsheet/count/${fieldName}`,
            method: 'POST',
            data: {
                rangeType: '',
            },
        }
    },
}

export default request
