import React, {
    useCallback,
} from 'react'

import usePrintContainerLabels from 'app/Apps/Containers/hooks/usePrintContainerLabels'

import {
    Container,
} from '../../../containers.types'
import PrintLabelOverview from './PrintLabelOverview'

type Props = {
    value: Pick<Container, 'serialNumber' | 'containerType'>
}

const PrintLabelOverviewContainer = ({
    value,
}: Props) => {
    const {
        serialNumber,
        containerType: {
            containerCode,
        },
    } = value
    const containerSerialNumber = serialNumber.slice(4)
    const {
        mutate: printLabelRequest,
    } = usePrintContainerLabels({
        loadMessage: 'Please stand by while we’re preparing the barcodes.',
    })

    const printLabel = useCallback(() => {
        printLabelRequest({
            containerSerialNumberTo: containerSerialNumber,
            containerSerialNumberFrom: containerSerialNumber,
            containerCode,
            label: `labels_${containerCode}_${containerSerialNumber}-${containerSerialNumber}`,
        })
    }, [
        containerCode,
        containerSerialNumber,
        printLabelRequest,
    ])

    return (
        <PrintLabelOverview
            printLabel={printLabel}
        />
    )
}

export default PrintLabelOverviewContainer
