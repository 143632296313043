import React, {
    useState,
} from 'react'

import SharedContextProvider from 'app/shared-components/SharedContext'

import SelectMethod from './SelectMethod'
import CreateManuallyForm from './CreateManuallyForm'
import CreateAutomaticallyForm from './CreateAutomaticallyForm'

export enum SelectedMethod {
    AUTOMATICALLY = 'AUTOMATICALLY',
    MANUALLY = 'MANUALLY'
}

const CreateInternalTransportForm = () => {
    const [
        method,
        setMethod,
    ] = useState<SelectedMethod>()

    const [
        formData,
        setFormData,
    ] = useState<SelectedMethod>()

    return (
        <SharedContextProvider value={{
            formData,
            setFormData,
            setMethod,
        }}
        >
            <>
                <SelectMethod
                    value={method}
                    setValue={setMethod}
                />
                {
                    method === SelectedMethod.MANUALLY && <CreateManuallyForm />
                }
                {
                    method === SelectedMethod.AUTOMATICALLY && <CreateAutomaticallyForm />
                }
            </>
        </SharedContextProvider>
    )
}

export default CreateInternalTransportForm
