import React from 'react'

import {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'
import SkyNetTable from 'app/shared-components/SkyNetTable/SkyNetTableContainer'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission'
import useHasPermission from 'app/hooks/useHasPermission'
import {
    RequestKeys,
} from 'app/hooks/useRequest'

import requests from './PreBookings.requests'
import tableConfig from './PreBookings.config'
import PreBookingCreate from './PreBookingCreate'
import PreBookingOverview from './PreBookingOverview'
import PreBookingUpdate from './PreBookingUpdate'
import PrebookingChangeHistory from './ChangeHistory'

const overviewTab: SidebarTab = {
    label: 'Overview',
    url: 'overview',
    Component: PreBookingOverview,
}

const updateTab: SidebarTab = {
    label: 'Data Update',
    url: 'data-update',
    Component: PreBookingUpdate,
}

const changeHistoryTab: SidebarTab = {
    label: 'Change History',
    url: 'change-history',
    Component: PrebookingChangeHistory,
}

const tableControls: TableControlTypes[] = [
    TableControlTypes.Search,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const PreBookings = () => {
    const canCreate = useHasPermission(['pre_booking_create'])
    const canUpdate = useHasPermission(['pre_booking_update'])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: overviewTab,
            permission: true,
        },
        {
            tab: updateTab,
            permission: canUpdate,
        },
        {
            tab: changeHistoryTab,
            permission: canUpdate,
        },
    ])

    return (
        <SkyNetTable
            createForm={canCreate ? PreBookingCreate : null}
            name={tableConfig.name}
            moduleName={requests.domainName}
            getDomainObject={requests.get}
            domainRequestKey={RequestKeys.getPreBookings}
            tabs={tabs}
            defaultTab="overview"
            tableControls={tableControls}
            tableConfig={tableConfig}
        />
    )
}

export default PreBookings
