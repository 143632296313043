import {
    LaneGroupTemperatureRange, LeaseType, LocationType,
} from 'app/types/enums'
import {
    DTOCardFormField, FieldInCard, NestedField,
} from 'app/types/form.types'
import {
    DeliveryServiceType, TransportModeRoad, CollServiceType,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    Country,
} from 'app/Apps/Masterdata/Countries/Countries.types'
import {
    AccountLane, LaneStatus,
} from '../AccountLanes.types'

const getFields = ({
    getAllowedValues,
    showFTL,
    showLTL,
    isDeliveryRoad,
    isCollection,
    handoverPointCountry,
    collectionDropoffPointCountry,
    exists,
}: {
    getAllowedValues: (val: Record<string, string>) => Record<string, string>,
    showFTL: boolean,
    showLTL: boolean,
    isDeliveryRoad: boolean,
    isCollection: boolean,
    handoverPointCountry?: Country | {id: number},
    collectionDropoffPointCountry?: Country | {id: number},
    exists: boolean
}): DTOCardFormField < keyof AccountLane>[] => {
    const generalFields: FieldInCard<keyof AccountLane>[] = [
        {
            id: 'column1',
            fields: [
                {
                    name: 'laneNumber',
                    componentName: 'InputSingleline',
                    labelKey: 'AccountLane.laneNumber',
                    disabled: true,
                },
                {
                    name: 'opportunity',
                    componentName: 'OpportunitiesSelector',
                    labelKey: 'AccountLane.opportunity',
                    required: true,
                },
                {
                    name: 'account',
                    componentName: 'AccountSelector',
                    labelKey: 'AccountLane.accountCompanyName',
                    disabled: true,
                },
                ...exists ? [{
                    name: 'contractBasisAccount',
                    componentName: 'AccountSelector',
                    labelKey: 'Prebooking.pricingAccount',
                    disabled: true,
                }] as NestedField<keyof AccountLane>[] : [],
                {
                    name: 'temperatureRange',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(LaneGroupTemperatureRange),
                    labelKey: 'AccountLane.temperatureRange',
                    disabled: true,
                },
                {
                    name: 'leaseType',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(LeaseType),
                    labelKey: 'AccountLane.leaseType',
                    required: true,
                    showInColumns: true,
                },
                {
                    name: 'originAirport',
                    componentName: 'LocationObjectSelector',
                    filterLocationType: LocationType.AIRPORT,
                    labelKey: 'AccountLaneForm.originAirport',
                    required: true,
                },
                {
                    name: 'destinationAirport',
                    componentName: 'LocationObjectSelector',
                    filterLocationType: LocationType.AIRPORT,
                    labelKey: 'AccountLaneForm.destinationAirport',
                    required: true,
                },
            ],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'laneStatus',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(LaneStatus),
                    labelKey: 'LaneQuoteRequest.selectedLaneStatus',
                    disabled: true,
                },
                {
                    name: 'customerExecutionProvisional',
                    componentName: 'InputSingleline',
                    labelKey: 'AccountLane.customerExecutionProvisional',
                },
                {
                    name: 'customerExecutionContacts',
                    componentName: 'MultipleContactObjectSelector',
                    labelKey: 'AccountLane.customerExecutionContacts',
                },
                {
                    name: 'consigneeProvisional',
                    componentName: 'InputSingleline',
                    labelKey: 'AccountLane.consigneeProvisional',
                },
                {
                    name: 'consigneeContacts',
                    componentName: 'MultipleContactObjectSelector',
                    labelKey: 'AccountLane.consigneeContacts',
                },
                {
                    name: 'laneComment',
                    componentName: 'LongText',
                    labelKey: 'AccountLane.laneComment',
                },
            ],
        },
    ]

    const transportModeRoad = {
        name: 'transportModeRoad',
        labelKey: 'LaneGroupCreateOrDuplicateDto.transportModeRoad',
        componentName: 'EnumSelector',
        allowedValues: getAllowedValues(TransportModeRoad),
        showInColumns: true,
        required: true,
    } as FieldInCard<keyof AccountLane>

    const isFtlPositioningTemperatureControlled = {
        name: 'isFtlPositioningTemperatureControlled',
        labelKey: 'AccountLane.isFtlPositioningTemperatureControlled',
        componentName: 'Checkbox',
    } as FieldInCard<keyof AccountLane>

    const isLtlPositioningTemperatureControlled = {
        name: 'isLtlPositioningTemperatureControlled',
        labelKey: 'AccountLane.isLtlPositioningTemperatureControlled',
        componentName: 'Checkbox',
    } as FieldInCard<keyof AccountLane>

    let transportModeFields = [transportModeRoad]

    if (showLTL) {
        transportModeFields = [
            ...transportModeFields,
            isLtlPositioningTemperatureControlled,
        ]
    }

    if (showFTL) {
        transportModeFields = [
            ...transportModeFields,
            isFtlPositioningTemperatureControlled,
        ]
    }

    const handoverFields: FieldInCard<keyof AccountLane>[] = [
        {
            id: 'column1',
            fields: [
                {
                    name: 'deliveryServiceType',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(DeliveryServiceType),
                    labelKey: 'AccountLane.deliveryServiceType',
                    required: true,
                },
                ...(isDeliveryRoad ? transportModeFields : []) as NestedField<keyof AccountLane>[],
            ],
        },
        {
            id: 'column2',
            fields: [
                ...(isDeliveryRoad ? [
                    {
                        name: 'handoverPointCountry',
                        componentName: 'CountryDataSelector',
                        labelKey: 'AccountLane.handoverPointCountry',
                        required: true,
                    },
                    {
                        name: 'handoverPointZipCode',
                        componentName: 'ZipCodeInput',
                        labelKey: 'AccountLane.handoverPointZipCode',
                        required: true,
                        country: handoverPointCountry,
                    },
                    {
                        name: 'handoverPointAddress',
                        componentName: 'InputSingleline',
                        labelKey: 'AccountLane.handoverPointAddress',
                    },
                ] as NestedField<keyof AccountLane>[] : []),
                {
                    name: 'handoverPointContacts',
                    componentName: 'MultipleContactObjectSelector',
                    labelKey: 'AccountLane.handoverPointContacts',
                },
            ],
        },
    ]

    const collectionFields: FieldInCard<keyof AccountLane>[] = [
        {
            id: 'column1',
            fields: [{
                name: 'collectionServiceType',
                componentName: 'EnumSelector',
                allowedValues: getAllowedValues(CollServiceType),
                labelKey: 'AccountLane.collectionServiceType',
                required: true,
            }],
        },
        {
            id: 'column2',
            fields: [
                ...(isCollection ? [
                    {
                        name: 'collectionDropoffPointCountry',
                        componentName: 'CountryDataSelector',
                        labelKey: 'AccountLane.collectionDropoffPointCountry',
                        required: true,
                    },
                    {
                        name: 'collectionDropoffPointZipCode',
                        componentName: 'ZipCodeInput',
                        labelKey: 'AccountLane.collectionDropoffPointZipCode',
                        required: true,
                        country: collectionDropoffPointCountry,
                    },
                    {
                        name: 'collectionDropoffPointAddress',
                        componentName: 'InputSingleline',
                        labelKey: 'AccountLane.collectionDropoffPointAddress',
                    },
                ] as NestedField<keyof AccountLane>[] : []),
                {
                    name: 'collectionDropoffPointContacts',
                    componentName: 'MultipleContactObjectSelector',
                    labelKey: 'AccountLane.collectionDropoffPointContacts',
                },
            ],
        },
    ]

    return [
        {
            id: 'general',
            title: 'General',
            className: 'general',
            fields: generalFields,
        },
        {
            id: 'handover',
            title: 'Handover',
            className: 'handover',
            fields: handoverFields,
        },
        {
            id: 'collection',
            title: 'Collection',
            className: 'collection',
            fields: collectionFields,
        },
    ]
}

export default getFields
